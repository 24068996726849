import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelpTipsService {
  private helpTips: BehaviorSubject<any> = new BehaviorSubject<any>({});
  helpTips$: Observable<any> = this.helpTips.asObservable();
  private helpTipsVisibilities: BehaviorSubject<any> = new BehaviorSubject<any>(this.getTipVisibilities());
  helpTipsVisibilities$: Observable<any> = this.helpTipsVisibilities.asObservable();
  private helpTipsSubject = new BehaviorSubject<number | null>(null);
  helpTipsSubject$ = this.helpTipsSubject.asObservable();
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private cookieService: CookieService,
  ) { 
    this.getInitialHelpTipState();
  }


  changeTipVisibility(key: string, visibility: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      let getTip = localStorage.getItem('tipsVisibilities');
      let tipVisibilities = getTip ? JSON.parse(getTip) : {};
      tipVisibilities[key] = visibility;
      localStorage.setItem('tipsVisibilities', JSON.stringify(tipVisibilities));
      this.helpTipsVisibilities.next(tipVisibilities);
    }

  }

  getTipVisibility(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      let getTip = localStorage.getItem('tipsVisibilities');
      let tipVisibilities = getTip ? JSON.parse(getTip) : {};
      return tipVisibilities[key];
    }
  }
  getTipVisibilities() {
    if (isPlatformBrowser(this.platformId)) {
      let getTip = localStorage.getItem('tipsVisibilities');
      let tipVisibilities = getTip ? JSON.parse(getTip) : {};
      return tipVisibilities;
    }
  }

  setHelpTips(replayData: any) {
    this.helpTips.next(replayData);
  }

  setHelpTipsVisibilities(state: number) {
    this.helpTipsSubject.next(state);
    if (isPlatformBrowser(this.platformId)) {
      this.cookieService.set('helptip', state === 1 ? '1' : '0');
    }
  }


 getInitialHelpTipState(){
    if (isPlatformBrowser(this.platformId)) {
      const cookieHelpTip = this.cookieService.get('helptip');
      const initialHelpTipState = cookieHelpTip ? parseInt(cookieHelpTip, 10) : 1;
      this.helpTipsSubject.next(initialHelpTipState);
    }
  }
}
